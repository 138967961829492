<template>
  <div class="RobotCenter" :style="{height: $store.state.menuFlag?'calc(100% - 20px)':'calc(100% - 110px)'}">
    <div class="RobotCenter_left">
      <div class="left_div" :class="{ ba: i == index }" v-for="(item, index) in nav" :key="index"
           @click="add(index)">
        {{ item }}
      </div>
    </div>
    <div class="RobotCenter_right">
      <RobotManagement v-if="i == 0"></RobotManagement>
      <!-- <AIknowledgeBase v-if="i==1"></AIknowledgeBase> -->
      <Script v-if="i == 1"></Script>
    </div>
  </div>
</template>
<script>
// import AIknowledgeBase from '../../components/RobotCenter/AIknowledgeBase.vue'
import RobotManagement from '../../components/RobotCenter/RobotManagement.vue'
import Script from '../../components/RobotCenter/Script.vue'

export default {
  data() {
    return {
      i: 0,
      nav: ['机器人管理', '公共知识库']
    }
  },
  components: {
    // AIknowledgeBase,
    RobotManagement,
    Script,
  },
  methods: {
    add(index) {
      this.i = index
    }
  },
  created() {
    this.$route.query.name && (this.i = +this.$route.query.name)
  },
  beforeRouteUpdate(to, from, next) {
    to.query.name && (this.i = +to.query.name)
    next()
  },
}
</script>
<style lang="scss" scoped>
.ba {
  background: #F0F7FD;
  color: #3388FF !important;
}

.RobotCenter {
  width: 100%;
  background: #fff;
  margin: 20px auto;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 150px 1fr;

  .RobotCenter_left {
    padding: 24px 0px 0px 0px;
    box-sizing: border-box;

    .left_div {
      width: 60px;
      height: 200px;
      border: 1px solid #EFEFEF;
      padding: 24px 14px 14px 14px;
      box-sizing: border-box;
      font-size: 20px;
      text-align: center;
      color: #69696A;
      margin: 0 auto;
    }
  }

  .RobotCenter_right {
    overflow: auto;
  }
}
</style>
