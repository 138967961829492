<template>
  <div class="RobotManagement">
    <div class="RobotManagement_head">
      <div class="RobotManagement_left">
        <!-- <span>
         <el-input v-model="input" placeholder="请输入内容"></el-input>
        </span> -->
        <span>
                 <el-input v-model="input" placeholder="请输入内容"></el-input>
                </span>
        <el-button type="primary" icon="el-icon-search" style="margin-left: 10px;">查询</el-button>
      </div>
      <div class="RobotManagement_right">
        <el-button type="primary" icon="el-icon-plus" style="margin-right: 64px;" @click="AddBot">新建Bot</el-button>
      </div>
    </div>
    <div class="RobotManagement_list" v-for="(item,index) in arr" :key="index">
      <div class="RobotManagement_item">
        <div @click="RobotCenterDetails(item)">
          <div class="item_1">
            <p>{{ item.Name }}</p>
          </div>
          <div class="item_2">
            <div class="item_text">RobotID：</div>
            <div>{{ item.Id }}</div>
          </div>
          <!-- <div class="item_2">
              <div class="item_text">场景：</div>
              <div>{{item.SceneName}}</div>
          </div> -->
          <div class="item_2">
            <div class="item_text">所有者：</div>
            <div>{{ item.OwnerName }}</div>
          </div>
          <div class="item_2">
            <div class="item_text">更新时间：</div>
            <div>{{ item.UpdateTime }}</div>
          </div>
          <div class="item_2">
            <div class="item_text">上线状态：</div>
            <div class="yuan2" v-show="item.Status=='02'"></div>
            <div class="yuan1" v-show="item.Status=='03'"></div>
            <div class="yuan" v-show="item.Status=='00'||item.Status=='01'"></div>
            <div>{{ item.StatusName }}</div>
          </div>
        </div>
        <div class="item_6">
          <div class="item_6_btn" @click="editrobot(item)" :class="{iswidth:item.Status=='03'||item.Status=='01'}">
            编辑
          </div>
          <div class="item_6_btn" @click="RobotCopy(item)" :class="{iswidth:item.Status=='03'||item.Status=='01'}">
            复制
          </div>
          <div class="item_6_btn" @click="AuditSubmit(item)" v-show="item.Status!='03'&&item.Status!='01'">审核</div>
          <div class="item_6_btn" @click="DeletetRobotCenter(item)"
               :class="{iswidth:item.Status=='03'||item.Status=='01'}">删除
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <div>
      <el-dialog
          top="2vh"
          :visible.sync="dialogBot"
          width="40%" @open="handleOpen" @close="clearFormData">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">{{ typeshow == 1 ? '新建Bot' : '编辑Bot' }}</span>
        </div>
        <div>
          <el-form ref="form" label-width="180px">
            <el-form-item label="项目:">
              <Cascader :value.sync="form.ProjectId" :options="ProjectList" @MyChange="ProjectChange"></Cascader>
            </el-form-item>
            <el-form-item label="问卷:">
              <el-select v-model="form.QuestionnaireId" placeholder="请选择">
                <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in CrfList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Robot类型:">
              <el-checkbox v-model="robotType" label="百应" @change="change_Type"></el-checkbox>
            </el-form-item>
            <el-form-item label="Robot:" v-if="form.Type === '01'" required>
              <el-select v-model="form.ExternalRobotId" placeholder="请选择" filterable @change="change_robot">
                <el-option :label="item.robotName" :value="item.robotDefId" v-for="(item,index) in robots_baiying"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Robot名称:" required>
              <el-input v-model="form.Name"></el-input>
            </el-form-item>
            <el-form-item label="Bot描述:">
              <el-input v-model="form.Description" type="textarea"></el-input>
            </el-form-item>
            <template v-if="form.Type === '00'">
              <el-form-item label="场景:">
                <el-cascader
                    v-model="form.SceneId"
                    :options="options"
                    :props="defaultProps"
                    @change="handleChange"></el-cascader>
              </el-form-item>
              <el-form-item label="方言类型:">
                <el-select v-model="form.Dialect" placeholder="请选择">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item,index) in Dialect"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="优先级匹配:">
                <el-select v-model="form.Priority" placeholder="请选择">
                  <el-option :label="item.Name" :value="item.Code" v-for="(item,index) in first"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="语言识别灵敏度:">
                <p style="width:200px;">
                  <el-select v-model="form.Sensitivity" placeholder="请选择">
                    <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </p>
                <p style="width:350px;">
                  调整机器语音识别的灵敏度，当环境嘈杂可降低灵敏
                  度进行过滤，当客户音量过低，可提高灵敏度。
                </p>
              </el-form-item>
              <el-form-item label="客户回答时长:">
                        <span style="width:200px;">
                          <el-input v-model="form.Interval"></el-input>
                        </span>
                <span style="margin-left: 10px;">分钟</span>
              </el-form-item>
            </template>
            <el-form-item label="线路:">
              <el-select v-model="form.Channel" placeholder="请选择">
                <el-option
                    v-for="item in lineList"
                    :key="item.id"
                    :label="item.phoneName"
                    :value="item.userPhoneId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="btn">
          <el-button @click="dialogBot = false">取 消</el-button>
          <el-button type="primary" @click="AddtRobotCenter">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog
          title="提示"
          :visible.sync="dialogAcoustic"
          width="50%">
        <el-table
            border
            :header-cell-style="{background: 'rgb(240, 247, 253)'}"
            :data="tableData"
            style="width: 100%">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              align="center"
              prop="Name"
              label="发音人"
              width="100">
          </el-table-column>
          <el-table-column
              align="center"
              prop="Gender"
              label="性别"
              width="60">
          </el-table-column>
          <el-table-column
              align="center"
              prop="Type"
              label="类型">
          </el-table-column>
          <el-table-column
              align="center"
              prop="Remark"
              label="特点">
          </el-table-column>
          <el-table-column
              align="center"
              width="350"
              label="录音小样">
            <template slot-scope="scope">
              <audio :src="scope.row.RecordingUrl" controls></audio>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="btn">
          <el-button @click="dialogAcoustic = false">取 消</el-button>
          <el-button type="primary" @click="dialogAcoustic = false">确 buququqd定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 审核弹出框 -->
    <div>
      <el-dialog
          :visible.sync="HeadReviewDialog"
          width="30%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">申请上线</span>
        </div>
        <div>
          <el-form label-width="80px">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="Text"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="HeadReviewDialog = false">取 消</el-button>
          <el-button type="primary" @click="addtoexamine">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 复制弹出框 -->
    <div>
      <el-dialog
          :visible.sync="CopyrobotDialog"
          width="30%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">复制机器人</span>
        </div>
        <div>
          <el-form label-width="100px">
            <el-form-item label="机器人名称">
              <el-input type="textarea" v-model="Name"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="CopyrobotDialog = false">取 消</el-button>
          <el-button type="primary" @click="Copyrobotqueding">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import RobotCenter from "../../api/RobotCenter"
import PatientCenter from "../../api/PatientCenter"
import derive from "../../api/derive"
import {local} from "../../utils/storage";
import Cascader from "@/components/Cascader.vue";

export default {
  components: {Cascader},
  data() {
    return {
      RoleType: local.get("RoleType"),
      options1: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      HeadReviewDialog: false,
      admin: localStorage.getItem("UserName"),
      Text: '',
      input: '',
      list: [],
      dialogBot: false,
      dialogAcoustic: false,
      RobotLine: [],
      robotType: false,
      form: {
        ProjectId: [],
        QuestionnaireId: '',
        SceneId: [1000, 1002, 1008],
        Name: '',
        SoundId: 1000,
        Label: '',
        Description: '',
        Dialect: '00',
        Priority: '01',
        Sensitivity: 2400,
        Interval: 5,
        MaxDuration: 0,
        Channel: "",
        Type: "00",
        ExternalRobotId: "",
      },
      options2: [],
      tableData: [],
      options: [],
      defaultProps: {
        value: "Id",
        label: 'Name',
        children: 'Children'
      },
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      Dialect: [],
      first: [],
      arr: [],
      RobotId: '',//机器人Id
      Name: '',//机器人Name
      ProjectList: [],
      CrfList: [],
      typeshow: 0,
      CopyrobotDialog: false,
      // 百应机器人列表
      robots_baiying: [],
      currentRobot: {},
      lineList: []
    }
  },
  created() {
    this.GetPageList()
  },
  methods: {
    Copyrobotqueding() {
      let obj = {
        Id: this.RobotId,
        Name: this.Name
      }
      RobotCenter.RobotCopyData(obj).then(res => {
        if (res.data.Status == 1) {
          this.CopyrobotDialog = false
          this.GetPageList()
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
        } else {
          alert(res.data.Message)
        }
        //  console.log(res);
      })
    },
    RobotCopy(item) {
      this.RobotId = item.Id
      this.Name = item.Name + '的副本'
      // console.log(item);
      this.CopyrobotDialog = true
    },
    //确定审核
    addtoexamine() {
      let obj = {
        RobotId: this.RobotId,
        Type: "00",
        Text: this.Text
      }
      RobotCenter.AuditSubmitData(obj).then(res => {
        if (res.data.Status == 1) {
          this.GetPageList()
          this.HeadReviewDialog = false
        } else {
          alert(res.data.Message)
          this.HeadReviewDialog = false
        }
        //    console.log(res);
      })
    },
    AuditSubmit(item) {
      this.HeadReviewDialog = true
      this.RobotId = item.Id
    },
    RobotCenterDetails(item) {
      this.$router.push({
        path: '/RobotCenterDetails',
        query: {Id: item.Id, qid: item.QuestionnaireId, Qname: item.Name}
      });
    },
    //删除机器人
    DeletetRobotCenter(item) {
      this.$confirm('此操作将永久删除机器人, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let list = []
        list.push(item.Id)
        RobotCenter.DeletetRobotCenterData(list).then(res => {
          if (res.data.Status == 1) {
            this.arr = this.arr.filter(row => {
              return row.Id != item.Id
            })
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            alert(res.data.Message)
          }
          //  console.log(res);
        })
      }).catch(() => {
      });
    },
    //机器人列表
    GetPageList() {
      RobotCenter.GetPageListData(this.listQuery).then(res => {
        if (res.data.Status == 1) {
          this.arr = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        // console.log(res);
      })
    },

    //项目列表
    async ProjectListfn() {
      derive.ProjectListData().then(res => {
        if (res.data.Status == 1) {
          this.ProjectList = res.data.Data
        } else {
          alert(res.data.Message)
        }
        // console.log(res,"机器人");
      })
    },
    ProjectChange(e) {
      // e = this.flatProjectId(e)
      const obj = {
        ProjectId: e
      }
      console.log(obj)
      derive.CRFSurfaceData(obj).then(res => {
        if (res.data.Status == 1) {
          this.CrfList = res.data.Data
        } else {
          alert(res.data.Message)
        }
        // console.log(res,'问卷');
      })
    },
    flatProjectId(ProjectId) {
      let arr = []
      ProjectId.forEach(item => {
        arr.push(item.slice(-1)[0])
      })
      return arr
    },
    async type() {
      //方言
      const Dialect = "FYLX"
      PatientCenter.BaseDataBigData(Dialect).then(res => {
        if (res.data.Status == 1) {
          this.Dialect = res.data.Data
        } else {
          alert(res.data.Message)
        }
        //    console.log(res,'方言');
      })
      //优先级匹配
      const first = "YXJ"
      PatientCenter.BaseDataBigData(first).then(res => {
        if (res.data.Status == 1) {
          this.first = res.data.Data
        } else {
          alert(res.data.Message)
        }
        //    console.log(res,'优先级匹配');
      })
    },
    // 声学模型
    async RobotSoundGetList() {
      RobotCenter.RobotSoundGetListData(this.listQuery).then(res => {
        if (res.data.Status == 1) {
          this.tableData = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        // console.log(res,'声学模型');
      })
    },
    //场景
    async RobotSceneGetList() {
      RobotCenter.RobotSceneGetListData().then(res => {
        // console.log(res,"场景");
        if (res.data.Status == 1) {
          this.options = res.data.Data.map((item) => {
            item.Children = item.Children.map(row => {
              row.Children = row.Children.map(p => {
                delete p.Children
                return p
              })
              return row
            })
            return item
          })
          //  console.log(this.options);
        } else {
          alert(res.data.Message)
        }
      })
    },
    // 线路
    async RobotLineGetList() {
      const parameter = {
        Key: '',
      }
      RobotCenter.RobotLineGetListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.RobotLine = res.data.Data
        } else {
          alert(res.data.Message)
        }
        //  console.log(res,'线路');
      })
    },
    handleChange() {

    },
    //确认
    AddtRobotCenter() {
      // console.log(this.form.SceneId);
      if (this.form.Name == "") {
        return alert('Robot名称不能为空')
      }
      if (this.typeshow == 1) {
        this.form.SceneId = this.form.SceneId[2]
      }

      let params = JSON.parse(JSON.stringify(this.form));
      if (Array.isArray(params.ProjectId)) params.ProjectId = params.ProjectId[0]
      console.log("=>(RobotManagement.vue:550) params", params);

      if (this.typeshow == 1) {
        RobotCenter.AddtRobotCenterData(params).then(res => {
          if (res.data.Status == 1) {
            this.arr.unshift(res.data.Data)
            this.dialogBot = false
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.GetPageList();
          } else {
            alert(res.data.Message)
          }
          // console.log(res);
        })
      } else {
        //编辑
        RobotCenter.EdittRobotCenterData(params).then(res => {
          // console.log(res);
          if (res.data.Status == 1) {
            // this.arr.unshift(res.data.Data)
            this.dialogBot = false
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.GetPageList();
          } else {
            alert(res.data.Message)
          }
        })
      }
    },
    //打开新建Bot
    AddBot() {
      if (this.RoleType != 'D') {
        this.$message({
          message: '对不起，您没有权限，请先联系管理员!',
          type: 'warning'
        });
        return;
      }
      this.dialogBot = true
      this.typeshow = 1
      this.ProjectListfn()
      this.type()
      this.RobotLineGetList()
      this.RobotSceneGetList()
      this.RobotSoundGetList()
      // this.GetChannelList()
    },
    async GetChannelList() {
      RobotCenter.GetChannelList().then(res => {
        // console.log(res,'channel')
        if (res.data.Status == 1) {
          this.options2 = res.data.Data;
        } else {
          alert(res.data.Message)
        }
      })
    },
    handleOpen() {
      this.getLineList()
    },
    //编辑新建Bot
    async editrobot(item) {
      await this.ProjectChange([item.ProjectId])
      await this.GetRobots_BaiYing()
      await this.ProjectListfn()
      this.robotType = item.Type === "01";
      this.form.ProjectId = [item.ProjectId]
      console.log("=>(RobotManagement.vue:632) item", item);

      this.typeshow = 2
      this.form = JSON.parse(JSON.stringify(item));
      this.type()
      await this.RobotLineGetList()
      await this.RobotSceneGetList()
      await this.RobotSoundGetList()
      this.dialogBot = true;
      // this.GetChannelList()
    },
    AddAcoustic() {
      this.dialogAcoustic = true
    },
    change_Type(val) {
      if (val) {
        this.form.Type = "01"
        this.GetRobots_BaiYing()
      } else {
        this.form.Type = "00"
      }
      // this.form.Type = val ? "01" : "00"
      console.log(this.form)
    },
    // 获取百应机器人
    async GetRobots_BaiYing() {
      RobotCenter.GetRobots_BaiYing().then(res => {
        if (res.data.Status == 1) {
          console.log(res.data.Data, "baiyiing")
          this.robots_baiying = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    // 机器人变化事件
    change_robot(val) {
      let findItem = this.robots_baiying.find(item => item.robotDefId === val)
      console.log("=>(RobotManagement.vue:680) findItem", findItem);
      this.form.Name = findItem.robotName
      this.currentRobot = findItem
      this.form.robotName = findItem.robotName
      this.form.sceneDefId = findItem.sceneDefId
    },
    // 获取线路列表
    async getLineList() {
      RobotCenter.GetPhones().then(res => {
        if (res.data.Status == 1) {
          console.log(res.data.Data, "baiyiing")
          this.lineList = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    clearFormData() {
      if (Array.isArray(this.form.ProjectId)) {
        this.form.ProjectId.length = 0
      } else {
        this.form.ProjectId = ""
      }
      this.form.QuestionnaireId = ""
      this.form.SceneId = [1000, 1002, 1008]
      this.form.Name = ""
      this.form.SoundId = 1000
      this.form.Label = ""
      this.form.Description = ""
      this.form.Dialect = "00"
      this.form.Priority = "01"
      this.form.Priority = 2400
      this.form.Interval = 5
      this.form.MaxDuration = 0
      this.form.Channel = ""
      this.form.Type = "00"
      this.form.ExternalRobotId = ""
      this.robotType = false
    }
  }
}
</script>
<style lang="scss" scoped>
.iswidth {
  width: 33.33% !important;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}

span {
  display: inline-block;
}

.yuan1 {
  width: 11px;
  height: 11px;
  background: #67C23A;
  border-radius: 50%;
  margin-right: 10px;
}

.yuan2 {
  width: 11px;
  height: 11px;
  background: #F56C6C;
  border-radius: 50%;
  margin-right: 10px;
}

.yuan {
  width: 11px;
  height: 11px;
  background: #FF9900;
  border-radius: 50%;
  margin-right: 10px;
}

.RobotManagement_head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 80px;
  background: #fff;
  line-height: 80px;
  // .RobotManagement_left{
  //     width: 1000px;
  // }
}

.RobotManagement_list {
  background: #fff;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .RobotManagement_item {
    width: 400px;
    // height: 280px;
    border: 1px solid #ECECEC;
    // box-shadow: 0px 12px 32px rgba(0,0,0,0.1);
    border-radius: 6px;

    .item_1 {
      height: 60px;
      background: #F0F7FD;
      font-size: 18px;
      color: #319EFD;
      line-height: 60px;
      margin-bottom: 10px;

      p {
        margin-left: 20px;
      }
    }

    .item_2 {
      color: #707070;
      font-size: 16px;
      margin-bottom: 10px;
      margin-left: 20px;
      display: flex;
      align-items: center;

      .item_text {
        width: 80px;
        text-align: right;
      }
    }

    // .item_3{
    //     color: #707070;
    //     font-size: 16px;
    //     margin-bottom: 10px;
    //     margin-left: 20px;
    // }
    // .item_4{
    //     color: #707070;
    //     font-size: 16px;
    //     margin-bottom: 10px;
    //     margin-left: 20px;
    // }
    // .item_5{
    //     color: #707070;
    //     font-size: 16px;
    //     margin-bottom: 42px;
    //     margin-left: 20px;
    // }
    .item_6 {
      width: 100%;
      border-top: 1px solid #ECECEC;
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      height: 50px;

      .item_6_btn {
        width: 25%;
        text-align: center;
        border-right: 1px solid #ECECEC;
        height: 50px;
        box-sizing: border-box;
        line-height: 50px;
        cursor: pointer;

        &:hover {
          color: #319EFD !important;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  }
}
</style>